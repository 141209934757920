.Main-Section-Advanced-Dashboard .small-box .content .inner h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
  color: white;

}

.Main-Section-Advanced-Dashboard a {
  text-decoration: none;
}


.Main-Section-Advanced-Dashboard .small-box:nth-child(even) {
  background-color: #63bff5;
  /* Alternate color 1 */
}

.Main-Section-Advanced-Dashboard .small-box:nth-child(odd) {
  background-color: red;
  /* Alternate color 2 */
}

.Main-Section-Advanced-Dashboard .small-box .content .inner p {
  font-size: 15px;
  color: white;
}

.Main-Section-Advanced-Dashboard .small-box>.inner {
  padding: 10px;
}

.main-advancedashboard .Map-Section .cardbox {
  height: 404px;
  border-top: 3px solid #00a65a;
  border-radius: 3px;
}

.main-advancedashboard .dataTable>tbody>tr {
  font-size: 13px;
}

.main-advancedashboard table.dataTable>thead>tr>th {
  font-size: 13px;
}

.Main-Section-Advanced-Dashboard .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  font-size: 14px;
}

.Main-Section-Advanced-Dashboard .small-box .content {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
}

.Main-Section-Advanced-Dashboard {
  padding: 8px 18px;
}

.Main-Section-Advanced-Dashboard .small-box .content .icon {
  align-items: center;
  font-size: 60px;
  color: #32323269;
  padding: 1px 0px;
  object-fit: contain;
  aspect-ratio: 1 / 1;
  transition: transform 0.6s;
}

.Main-Section-Advanced-Dashboard .card {
  border: none;
  border-radius: 5px;
}

.Main-Section-Advanced-Dashboard .small-box .content:hover .icon {
  transform: scale(1.3);
}

.main-advancedashboard .red-cardddd {
  background: #dd4b39;
  padding: 15px 10px 5px 10px;
  border-radius: 5px;
}

.main-advancedashboard .red-cardddd .small-box-footer {
  text-align: start;
  background: none;
}

.main-advancedashboard .blue-cardddd {
  background: #63bff5;
  padding: 15px 10px 5px 10px;
  border-radius: 5px;
}

.main-advancedashboard .sold-txtttt {
  font-size: 16px;
  color: #FFF;
  font-weight: 600;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.main-advancedashboard .blue-cardddd .small-box-footer {
  text-align: start;
  background: none;
}

/* Map Section Css */

.main-advancedashboard .box-warning {
  border-top: 3px solid #f39c12;
}

.main-advancedashboard .box-categoris {
  border-top: 3px solid #3c8dbc;
}

.main-advancedashboard .Map-Section .box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #dd4b39;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  height: 404px;
}

.main-advancedashboard .AdvanceDashboard {
  position: sticky;
  top: 0;
}


.main-advancedashboard .text {
  font-size: 14px;
  color: rgb(205, 204, 204);
}

.main-advancedashboard .text-one {
  font-size: 14px;
  color: black;
  margin-bottom: 0px;
}

.Map-Section {
  padding: 8px 18px;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

/* Tables Css  */
.Map-Section .box-two h3.box-title {
  font-size: 14px;
}

.Map-Section .box-two .box-header>.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.Map-Section .box-two .accordion .accordion-button:not(.collapsed)::after {
  background-image: url('../../../../public/minus.png');
  transition: none;
}

.Map-Section .box-two .accordion .accordion-button::after {
  background-image: url('../../../../public/plus.png');
  transition: none !important;
}

.Map-Section .box-two .accordion .accordion-body {
  height: 350px;
}

.Map-Section .box-two .accordion .accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.Map-Section .box-two label {
  margin-left: 13px;
  font-size: 13px;
}

/* media */

@media(min-width:0px) and (max-width:576px) {
  .Main-Section-Advanced-Dashboard .card {
    margin-top: 5%;
  }

  .Main-Section-Advanced-Dashboard .small-box .content .icon {
    font-size: 40px;
  }

  .main-advancedashboard .box-warning {
    margin-top: 8%;
  }

  .main-advancedashboard .Map-Section .box {
    margin-top: 8%;
  }

  .main-advancedashboard .box-categoris {
    margin-top: 8%;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .Main-Section-Advanced-Dashboard .card {
    margin-top: 5%;
  }

  .Main-Section-Advanced-Dashboard .small-box .content .icon {
    font-size: 40px;
  }

  .main-advancedashboard .box-warning {
    margin-top: 3%;
  }

  .main-advancedashboard .Map-Section .box {
    margin-top: 3%;
  }

  .main-advancedashboard .box-categoris {
    margin-top: 3%;
  }
}

@media(min-width:993px) and (max-width:1200px) {
  .Main-Section-Advanced-Dashboard .card {
    margin-top: 5%;
  }

  .Main-Section-Advanced-Dashboard .small-box .content .icon {
    font-size: 40px;
  }

  .main-advancedashboard .box-warning {
    margin-top: 3%;
  }

  .main-advancedashboard .Map-Section .box {
    margin-top: 3%;
  }

  .main-advancedashboard .box-categoris {
    margin-top: 3%;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", "sans-serif" !important;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.modal-header {
  border-bottom: 0;
}

.sidebarHeadClose .sidebar .top_section h1 {
  font-size: 18px;
  margin-bottom: 0;
}

.sidebarHeadopen .sidebar .top_section h1 {
  margin-bottom: 0;
}

/* .input-mandatory {
  --bs-gutter-x: -0.5rem !important;
  width: 95%;
} */

.sidebar-x-scroll {
  --bs-gutter-x: 0px !important;
}

.sidebarHeadopen {
  width: 330px;
  transition: 0.3s ease;
}

.sidebarHeadClose {}

.dashboradopen {
  width: 100%;
}

.allRoutesMain {
  height: 100vh;
  overflow-y: auto;
  background: rgb(242 242 242);
}

.title {
  font-size: 24px;
}

.sidebarHeadClose .accordion-button::after {
  background: transparent;

}

.sidebarHeadClose .accordion-button {
  padding-left: 40px;
}

.sidebarHeadClose .sidebar .logo {
  width: 80px;
}

.form-check-input:checked {
  background-color: #45b4ff;
  border-color: #45b4ff;
}

.toasterstyle {
  z-index: 999999 !important;
  position: relative !important;
}
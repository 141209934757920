.header .header-icons {
  display: flex;
  align-items: center;
}

.header .header-icons .icon {
  margin-right: 10px;
  margin-top: 7px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 9;
  background: #363795;
}

.header .desktop-bar-btnnn {
  display: block;
}


.header .mobile-view-bar {
  display: none;
}


.header .search-area .form-control {
  background: #e3e3e3;
  border-width: 0px;
  padding-left: 20px;
  border-radius: 60px 0 0 60px;
  box-shadow: none;
}

.header .header_icons {
  background-color: #fff;
  padding: 9px;
  border-radius: 50%;
  line-height: 1;
}

.header .logout-btnnn {
  border: 1px solid #acabab;
  background: #f3f2f2;
  font-size: 14px;
  padding: 5px 20px;
}

.header .logout-btnnn:hover {
  border: 1px solid #807f7f;
  background: #c8c8c8;
}

.header .search-area .input-group-text {
  background: #e3e3e3;
  border-width: 0px;
  border-radius: 60px;
  padding: 3px 9px;
}

.header nav h1 {
  margin: 0% 20px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #FFF;
}

.header nav {
  background: #45b4ff;
  padding: 5px 0px;
}

.header .dropdown-toggle::after {
  display: none !important;
}

.header .dropdown-item {
  padding: 5px 34px;
}

.header .dropdown-item:hover {
  background: #FFF;
}

.header .navbar-toggler {
  margin-right: 3%;
}

.header .navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  left: -84px;
  top: 31px;
  z-index: 99;
}

.header .blue-bg {
  background: #45b4ff;
  padding: 0px 0px 10px 0px;
}

.header .blue-bg .avtar-img {
  width: 100%;
  padding: 10px 40px 0px 40px;
  border-radius: 50%;
}

.header .logout_content {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 14px;
  margin-right: 20px;
  color: #FFF;
}

.header .logout_content_sec {
  font-size: 14px;
  color: #FFF;
}

.header .logout_content_secccc {
  font-size: 12px;
  color: #FFF;
  font-weight: 400px;
}

.header .power-icon {
  color: #FFF;
}

.header .super_admintxt {
  margin-bottom: 0;
  font-size: 12px;
}

.header .header-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .header-text p {
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 0px;
  display: flex;
  justify-self: center;
  align-items: center;
}

.header .header-text svg {
  font-size: 17px;
  /* margin-right: 3px; */
  margin-left: 15px;
  color: #FFF;
}

.header .navbar-toggler-icon {
  font-size: 15px;
}

@media screen and (min-width: 0px) and (max-width:576px) {
  .header .header-icons {
    justify-content: end;
  }

  .header .desktop-bar-btnnn {
    display: none;
  }


  .header .mobile-view-bar {
    display: block;
  }
}

@media screen and (min-width: 577px) and (max-width:767px) {
  .header .header-icons {
    justify-content: end;
  }

  .header .desktop-bar-btnnn {
    display: none;
  }


  .header .mobile-view-bar {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width:991px) {
  .header .header-icons {
    justify-content: end;
  }
}
.add-varient {
  border: none;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  background: #45b4ff;
  color: #fff;
}

.save-btnnnnnn {
  border: none;
  font-size: 16px;
  border-radius: 5px;
  background: green;
  color: #fff;
}

.save-btnnnnnn:hover {
  border: none;
  font-size: 16px;
  border-radius: 5px;
  background: green;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
